.page-heading-1 {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 20px;
}

.home-section {
  padding-top: 12px;
}

.home-container {
  display: flex;
  justify-content: center;
}

.image-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow: hidden;
}

.image-container:hover .overlay {
  opacity: 1;
}

.main-images {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 768px) {
  .main-images {
    height: 200px;
  }
}

@media (max-width: 576px) {
  .main-images {
    height: 100px;
    width: auto;
  }

  .main-overlay {
    height: 40px;
    width: auto;
  }

  .home-container {
    max-width: 100%;
  }

  .margin-right {
    margin-left: 1rem;
  }
}

.main-images,
.overlay {
  border-radius: 10px;
  margin-left: 0.5rem;
}

.submit-button {
  background-color: #fa4454;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.overlayText {
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.5rem;
}

.main-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.main-modal-content {
  background-color: rgb(39, 36, 36);
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-modal-content h1 {
  color: white;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.main-modal-content input {
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  margin-bottom: 1rem;
  padding-left: 1rem;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
}

.main-modal-content button {
  background-color: #38393a;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: 0.5s;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
