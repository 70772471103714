* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

html {
  color: #f3f4f6;
  background-color: #12131e;
}

header {
  z-index: 10;
  border-bottom: 0.0625rem solid #a1a9ba40;
}

.nav-container {
  display: flex;
  justify-content: center;
}

.nav a {
  margin: 0 2rem;
}

.nav ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-link {
  position: relative;
  color: #fff;
  text-decoration: none;
  padding-bottom: 8px;
}

.logo {
  width: 250px;
  height: 50px;
  margin: 0 10px;
}

.nav svg {
  height: 1rem;
  width: auto;
}

/* Leaderboard CSS  */

.result-title {
  margin-bottom: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

th,
td {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

td {
  text-align: center;
  margin-top: 1rem;
  color: white;
  border: none;
}

.submit-btn {
  background-color: #01ccff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: clamp(0.8rem, 3vw, 1.1rem);
  font-weight: 400;
  height: clamp(30px, 4vw, 45px);
  margin-top: 1rem;
  outline: none;
  padding: clamp(1px, 1vw, 6px) clamp(3px, 2vw, 15px);
  width: 100%;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.settings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40vh;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 25px;
}

.settings-container span {
  margin-left: 1rem;
}

.settings-container p {
  margin-top: 1rem;
}

.bottom-img {
  margin-right: 1rem;
}

.report-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 1rem;
}

.report-align {
  width: 1000px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  text-align: center;
}

.report-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  border-radius: 5px;
  padding: clamp(1px, 1vw, 6px) clamp(3px, 2vw, 15px);
  width: fit-content;
}

.nav-link:after {
  position: absolute;
  bottom: 0;
  content: '';
  height: 2px;
  left: 50%;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-link:hover:after {
  width: 100%;
  left: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    width: 100%;
  }

  .nav-container {
    flex-direction: column;
    padding: 10px;
    margin-left: 0;
    height: 80px;
  }


  .nav ul {
    margin-top: 10px;
    justify-content: center;
    font-size: 8px;
  }

  .nav svg {
    height: 0;
    width: auto;
  }

  .nav-link {
    white-space: nowrap;
  }

  .logo {
    margin: -5px;
    width: 200px;
    height: auto;
  }

  .nav a {
    margin: 0 12px;
    font-size: 14px;
  }

  .button-container {
    width: 200px;
  }

  .btn {
    width: 350px;
  }
}

@media (max-width: 481px) {
  body {
    width: 100vw;
  }

  .burgermenu-bar {
    position: absolute;
    top: 15px;
    padding: 15px 25px;
    font-size: 30px;
  }

  .modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    width: 100%;
  }

  .nav-container {
    padding: 0px;
    height: 80px;
  }

  .nav ul {
    flex-direction: column;
    align-items: start;
    margin-top: 10px;
  }

  .nav li {
    position: relative;
    display: flex;
    top: 245px;
    left: 0;
    width: 300px;
    padding: 20px;
    background-color: #12131e;
    margin-top: -1px;
    z-index: 2;
  }

  .nav svg {
    height: 0;
    width: auto;
  }

  .logo {
    position: absolute;
    margin: -5px;
    top: 30px;
    left: 25%;
    width: 200px;
    height: auto;
  }

  .nav a {
    margin: 0 12px;
    font-size: 18px;
  }

  .button-container {
    width: 200px;
  }

  .btn {
    width: 350px;
  }

  .nav-bigger {
    display: none;
  }
}

@media (min-width: 481px) {
  .burgermenu-bar {
    display: none;
  }

  .nav-smaller {
    display: none;
  }
}
