.profile-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.loader-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.profile-right-container {
  margin-top: 2rem;
  margin-left: 1rem;
}

.profile-container h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 2rem;
}

.stats-container {
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100px;
  background-color: #2a2d31;
  border-radius: 10px;
}


.stats-container h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-left: 2rem;
  text-align: center;
}

.profile-bottom-left .game-stats-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  max-width: 1000px; 
  margin-top: 1rem;
  margin-left: 1rem;
}

.profile-bottom-left .stat-box {
  flex: 1 1 calc(50% - 20px);
  height: 230px;
  background-color: #211e25;
  color: #fff;
  display: flex;
  border-radius: 8px;
}

.stat-box p {
  margin-left: 2rem;
  margin-top: 1rem;
  font-size: 20px;
}

.profile-bottom-left h1 {
  font-size: 28px;
  margin-left: 1rem;
  margin-top: 2rem;
}

.game-stat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2d31;
  text-align: center;
  border-radius: 20px;
  padding: 40px;
  margin-top: 4rem;
  height: 80px;
}

.game-stat-container h3 {
  margin-left: 1rem;
}



.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 3rem;
}

@media screen and (max-width: 768px) {
  .stats-btn {
    width: 100%;
  }

  .button-container {
    width: 100%;
  }

  .profile-container {
    text-align: center;
  }

  .stats-container {
    width: 100%;
  }

  .stats {
    margin-bottom: 10px;
  }

  .stats-h2 {
    margin-bottom: 4rem;
    margin-left: 10rem;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border-bottom: 0.5px solid #3a3939;
}

.pagination-button {
  background-color: #384c5f;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: clamp(0.8rem, 3vw, 1.1rem);
  font-weight: 400;
  margin-top: 1rem;
  outline: none;
  padding: clamp(1px, 1vw, 6px) clamp(3px, 2vw, 15px);
  margin-left: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 200px;
}

.stats-btn {
  background-color: #443f3f;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: clamp(0.8rem, 3vw, 1.1rem);
  font-weight: 400;
  height: 28px;
  margin-top: 0.4rem;
  outline: none;
  width: 40%;
}

@media screen and (max-width: 768px) {
  .stats-btn {
    width: 100%;
  }

  .button-container {
    width: 100%;
  }
}


#main-h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 10%;
  font-size: 3rem;
  font-weight: 700;
  flex-direction: column;
}

.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3a3939;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #777c77;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider::before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

.on
{
  display: none;
}

.on, .off
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 35%;
  font-size: 12px;
  font-family: Verdana, sans-serif;;
}

.off
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 57%;
  font-size: 12px;
  font-family: Verdana, sans-serif;;
}

input:checked+ .slider .on
{display: block;}

input:checked + .slider .off
{display: none;}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;}


.stats-h2 {
  margin-top: 4rem;
  margin-right: 30rem;
}

.top-right {
  position: fixed;
  top: 90px;
  right: 60px;
}

.toggle-container {
  position: relative;
  width: 700px; 
  height: 50px; 
  border-radius: 25px; 
  overflow: hidden; 
  cursor: pointer;
  display: flex;
}

.toggle-button {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  font-size: 20px; 
  color: white; 
  width: 50%;
}

.toggle-button.one.active {
  background-color: #262926; 
  color: white;
}

.toggle-button.one {
  background-color: #ffffff; 
  color: black;
}

.toggle-button.two.active {
  background-color: #262926;
  color: white;
}

.toggle-button.two {
  background-color: #ffffff; 
  color: black;
}

.match-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  margin-top: 2rem;
}

.match-box  {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 90%; 
  background-color: #211e25;
  height: 90px;
  border-radius: 25px;
  margin-top: 2rem;
}

.match-box h2 {
  margin-left: 1rem;
}

.match-participant, .match-score, .match-result {
  display: flex;
  justify-content: center;
  align-items: center;
}

.match-participant {
  justify-content: flex-start; 
  flex-grow: 1; 
}

.match-score {
  flex-grow: 1; 
  justify-content: center; 
  width: 20%;
}

.match-result {
  flex-grow: 1; 
  justify-content: flex-end; 
  color: red; 
  margin-right: 3rem;
}

