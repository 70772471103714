.youtube-wrapper {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .youtube-wrapper {
    position: relative;
    overflow: hidden;
    justify-content: center;
    padding-top: 56.25%;
    margin-left: 0;
  }

  .video {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ranks {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.rank {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 768px) {
  .rank {
    width: 40px;
    height: 40px;
  }
}

.rank:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.submit {
  margin-top: 20px;
  width: 37%;
  height: 50px;
  border-radius: 10rem;
  background-color: #f5f5f5;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.submit-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-example-heading {
  margin-right: 2.5rem;
}
