.game-btn {
  background-color: white;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 20px;
  text-decoration: none;
  color: black;
  margin-top: 2rem;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 75vh;
}

.home-logo {
  width: 800px;
}

.multiplayer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.leave-queue-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff4d4d;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

.leave-queue-btn:hover {
  background-color: #c00;
}

.navlink {
  text-decoration: none;
  color: black;
}


.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85vh;
  justify-content: space-between;
}

.top-info {
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: space-around;
}

.youtube-wrapper .video {
  width: 1000px;
  height: 550px;
}

.spacing-div {
  height: 100px;
}

.navlink-btn {
  text-decoration: none;
  color: black;
}

.nav-item {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: 6px;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .home-logo {
    width: 90vw;
  }
}

@media (max-width: 480px) {
  .home-logo {
    width: 20vw;
  }
}

.setting-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-content {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.modal-example-image {
  margin-left: 0.9rem;
}

.modal-example-wrong {
  margin-right: 3rem;
}

.top-right-container {
  position: fixed; 
  top: 0;
  right: 0;
  display: flex;
  align-items: center; 
  gap: 10px;
}

.navlink-submit-btn {
  background-color: white; 
  border-radius: 15px; 
  padding: 5px; 
  font-size: 20px; 
  text-decoration: none;
  color: black;
}

.back-submit {
  display: flex;
  justify-content: space-between; /* Adjusts children to each side */
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0; /* Extend the container across the top */
  margin: 10px;
  gap: 10px;
}

.ml-2 {
  font-size: 23px;
  margin-left: 9px;
}

.coin-container {
  display: flex;
  margin-top: 0.3rem;
  margin-right: 0.4rem;
  justify-content: flex-end

}


@keyframes brightness {
  0%,
  50%,
  100% {
    filter: blur(0px) brightness(120%)
      drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1))
      drop-shadow(0 0 5px rgba(255, 255, 255, 0.075))
      drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045))
      drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
  }
  25%, 75% {
    filter: blur(1px) brightness(50%)
      drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1))
      drop-shadow(0 0 5px rgba(255, 255, 255, 0.075))
      drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045))
      drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
  }
}

@keyframes spin {
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
.spinningasset {
  text-align: left;
  transition: all 0.4s ease-out;
  cursor: pointer;
  animation: brightness 2.5s infinite linear;
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    width: 11px;
    margin: auto;
    background-size: 100px 400%;
    background-position: center;
  }
  > div {
    position: relative;
    z-index: 2;
    perspective: 10000px;
    transform-style: preserve-3d;
    transform-origin: center;
    animation: spin 2.5s infinite linear;
    > * {
      width: 100%;
      height: 100%;
      position: absolute;
      backface-visibility: inherit;
      background-size: cover;
    }

    > div:first-child {
      transform: translateZ(-6px);
    }
    > div:last-child {
      transform: translateZ(6px);
      background-image: url(https://gloot.netlify.app/images/prototype/Coins_side_front.svg);
    }
    em {
      filter: blur(0.5px);
      &:first-of-type {
        transform: translateZ(0px) rotateY(-6deg);
      }
      &:last-of-type {
        transform: translateZ(0px) rotateY(6deg);
      }
    }
    i {
      filter: blur(0.5px);
      &:nth-of-type(1) {
        transform: translateZ(-5px);
      }
      &:nth-of-type(2) {
        transform: translateZ(-4px);
      }
      &:nth-of-type(3) {
        transform: translateZ(-3px);
      }
      &:nth-of-type(4) {
        transform: translateZ(-2px);
      }
      &:nth-of-type(5) {
        transform: translateZ(-1px);
      }
      &:nth-of-type(6) {
        transform: translateZ(0px);
      }
      &:nth-of-type(7) {
        transform: translateZ(1px);
      }
      &:nth-of-type(8) {
        transform: translateZ(2px);
      }
      &:nth-of-type(9) {
        transform: translateZ(3px);
      }
      &:nth-of-type(10) {
        transform: translateZ(4px);
      }
      &:nth-of-type(11) {
        transform: translateZ(5px);
      }
    }
  }

  &.is-sm {
    width: 24px;
    height: 24px;
    transform: scale(0.24);
    transform-origin: left top;
    filter: none;
  }

  &.coin {
    > div {
      width: 100px;
      height: 100px;
      > div:first-child {
        background-image: url(https://gloot.netlify.app/images/prototype/Coins_side_back.svg);
      }
      > div:last-child,
      &::after,
      i,
      em {
        background-image: url(https://gloot.netlify.app/images/prototype/Coins_side_front.svg);
      }
    }
  }

  &.token {
    filter: drop-shadow(0 0 3px rgba(236, 121, 254, 0.3))
      drop-shadow(0 0 6px rgba(236, 121, 254, 0.2))
      drop-shadow(0 0 20px rgba(236, 121, 254, 0.1))
      drop-shadow(0 0 30px rgba(236, 121, 254, 0.05));
    &:hover {
      filter: drop-shadow(0 0 3px rgba(236, 121, 254, 0.8))
        drop-shadow(0 0 6px rgba(236, 121, 254, 0.6))
        drop-shadow(0 0 20px rgba(236, 121, 254, 0.4))
        drop-shadow(0 0 30px rgba(236, 121, 254, 0.2));
    }
    > div {
      width: 100px;
      height: 112px;
      > div:first-child {
        background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632768480/Marketing/202109_gloot2/Tokens_side_back.svg);
      }
      > div:last-child,
      &::after,
      i,
      em {
        background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632768480/Marketing/202109_gloot2/Tokens_side_front.svg);
      }
    }

    &.is-sm {
      transform: scale(0.24) translateY(-33%);
    }
  }

  &.ticket {
    transform: translate;
    filter: drop-shadow(0 0 3px rgba(250, 234, 148, 0.4))
      drop-shadow(0 0 6px rgba(250, 234, 148, 0.3))
      drop-shadow(0 0 20px rgba(250, 234, 148, 0.2))
      drop-shadow(0 0 30px rgba(250, 234, 148, 0.1));

    &:hover {
      filter: drop-shadow(0 0 3px rgba(250, 234, 148, 0.6))
        drop-shadow(0 0 6px rgba(250, 234, 148, 0.4))
        drop-shadow(0 0 20px rgba(250, 234, 148, 0.2))
        drop-shadow(0 0 30px rgba(250, 234, 148, 0.1));
    }
    > div {
      width: 150px;
      height: 80px;
      > div:first-child {
        transform: translateZ(-1px);
      }
      > div:last-child {
        transform: translateZ(1px);
      }
      i {
        display: none;
      }
      em {
        &:first-of-type {
          transform: translateZ(0px) rotateY(-1deg);
        }
        &:last-of-type {
          transform: translateZ(0px) rotateY(1deg);
        }
      }
      > div:first-child {
        background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632816242/Marketing/202109_gloot2/ticket_side_back.svg);
      }
      > div:last-child,
      &::after,
      i,
      em {
        background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632816242/Marketing/202109_gloot2/ticket_side_front.svg);
      }
    }
  }

  &.ticket2 {
    transform: translate;
    > div {
      width: 173px;
      height: 150px;
      > div:first-child {
        transform: translateZ(-1px);
      }
      > div:last-child {
        transform: translateZ(1px);
      }
      i {
        display: none;
      }
      em {
        &:first-of-type {
          transform: translateZ(0px) rotateY(-1deg);
        }
        &:last-of-type {
          transform: translateZ(0px) rotateY(1deg);
        }
      }
      > div:first-child {
        background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632818517/Marketing/202109_gloot2/ticket_2_back.svg);
      }
      > div:last-child,
      &::after,
      i,
      em {
        background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632818305/Marketing/202109_gloot2/ticket_2_front.svg);
      }
    }

    &.is-sm {
      width: 40px;
      transform: scale(0.24) translateY(-100%);
    }
  }
}

/*----------------------------*/
/* CARD */
/*----------------------------*/

.card {
  background: radial-gradient(
    100% 100% at 50% 5%,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 0.5rem;
  backdrop-filter: blur(4px);
  > div {
    position: relative;
    z-index: 2;
    background: #2d2d59;
    border-radius: 0.5rem;
    padding: 1rem;
    backdrop-filter: blur(8px);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05),
      0 1.5px 1.1px rgba(0, 0, 0, 0.034), 0 3.6px 2.5px rgba(0, 0, 0, 0.048),
      0 6.8px 4.8px rgba(0, 0, 0, 0.06), 0 12.1px 8.5px rgba(0, 0, 0, 0.072),
      0 22.6px 15.9px rgba(0, 0, 0, 0.086), 0 54px 38px rgba(0, 0, 0, 0.12);
  }

  &.is-alt {
    background: transparent;
    border: none;
    > .card-content {
      background: #8d92f0;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 1.5rem;
      bottom: 1.5rem;
      width: 4rem;
      background: linear-gradient(to bottom, #1880b4 0%, #13d4bb 100%);
      border-radius: 0.25rem;
      transition: all 0.6s ease-out 0.22s;
    }
    &::before {
      left: 0;
      transform: translateX(1rem);
    }
    &::after {
      right: 0;
      transform: translateX(-1rem);
    }
  }
}
